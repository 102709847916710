const input = document.getElementById("searchInput");
const phrases = ["Kubek", "Notes", "Parasol"];
let index = 0;
let charIndex = 0;
let currentPhrase = "";
let deleting = false;
let stopAnimation = false;

function typeEffect() {
  if (stopAnimation) return; 
  
  if (deleting && charIndex > 0) {
    currentPhrase = currentPhrase.slice(0, -1);
    charIndex--;
  } else if (deleting && charIndex === 0) {
    deleting = false;
    index = (index + 1) % phrases.length;
  } else if (!deleting && charIndex < phrases[index].length) {
    currentPhrase += phrases[index].charAt(charIndex);
    charIndex++;
  } else if (charIndex === phrases[index].length) {
    deleting = true;
  }

  input.placeholder = currentPhrase;

  setTimeout(typeEffect, deleting ? 100 : 200);
}

setTimeout(() => {
  deleting = true;
  typeEffect();
}, 2000);

input.addEventListener("focus", function() {
  stopAnimation = true; 
  input.placeholder = "";
  input.value = "";
  input.removeAttribute("readonly");
  input.classList.add("-active");
});

input.addEventListener("blur", function() {
  input.classList.remove("-active");
  input.setAttribute("readonly", true);
  currentPhrase = "";
  charIndex = 0;
  deleting = false;
  stopAnimation = false;
  setTimeout(typeEffect, 1000);
});
